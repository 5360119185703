import React from "react"

interface SectionProps {
    className?: string
    children?: React.ReactNode
}


const Section = ({ children, className, ...props }: SectionProps) => {
    return <div className={"w-full m-auto max-w-[1440px] px-5 md:px-10 py-12 " + className} {...props}>
        {children}
    </div>
}

export default Section